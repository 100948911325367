/* eslint-disable */

import React, {
  Suspense,
  useEffect,
  useState,
  useRef
} from 'react';
import * as ReactDOM from 'react-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import $ from "jquery";
import { updateState, selectMedium } from '../counter/counterSlice';
import * as searchStore from '../../store/reducers/search';
import NoResults from './noResults';
import Paging from './paging';
import RefineSearch from './refineSearch';
import SortResults from './sortResults';
import useTopicFunctions from '../../hooks/useTopicFunctions.js';


// if (window.location.pathname.includes('searchAction')) {
//   require('./search.css');
// }

const NavBar = React.lazy(() => import('../../components/navBar'));
const SearchResultsTopics = React.lazy(() => import('./searchResultsTopics'));
const SearchResultsDrugs = React.lazy(() => import('./searchResultsDrugs'));
const SearchResultsAll = React.lazy(() => import('./searchResultsAll'));
const TopicBanner = React.lazy(() => import('../../components/topicBanner'));
const TopicToolsSticky = React.lazy(() => import('../../components/topicToolsSticky'));
const Footer = React.lazy(() => import('../../components/footer'));
const SavedSearchModal = React.lazy(() => import('../../components/savedSearchModal'));

let tgg = window.tgg;
let defiant = window.defiant;
let submitform = window.submitform;
let getURL = window.location.href;

const intVariable = 1000;

$(window).on("resize",function(){
    let sliderCheck = $("#tgsearch .slider-arrow");
    if($(sliderCheck).hasClass('show')) {
      if($(window).width() <= 315) {
        $(sliderCheck).css("left","198px");
      }
      else{
        $(sliderCheck).css("left","290px");
      }
    }
});


$(document).on('click', '#tgsearch .slider-arrow', function (e) {
  if ($(this).hasClass('show')) {
    if(screen.width <= 315) {
      $("#sidemenu-container").animate({
        left: "-198px"
      }, 700, function () {
        // Animation complete.
        console.log('Guideline hide');
      });
    }
    else {
      $("#sidemenu-container").animate({
        left: "-290px"
      }, 700, function () {
        // Animation complete.
        console.log('Guideline hide');
      });
    }

    $("#tgsearch .slider-arrow").animate({
      left: "0"
    }, 700, function () {
      // Animation complete.
      console.log('Slider minimized');
    });
    $(this).removeClass('show').addClass('hide');
  }
  else {
     $("#sidemenu-container").animate({
      left: "0px"
    }, 700, function () {
      // Animation complete.
      console.log('Guideline show');
    });
    if(screen.width <= 315) {
      $("#tgsearch .slider-arrow").animate({
        left: "198px"
      }, 700, function () {
        // Animation complete.
        console.log('Slider show');
      });
    }
    else {
      $("#tgsearch .slider-arrow").animate({
        left: "290px"
      }, 700, function () {
        // Animation complete.
        console.log('Slider show');
      });
    }
    $(this).removeClass('hide').addClass('show');
  }
  e.stopPropagation();
});

const Search = (props) => {
  const { pathname, search, push } = props;
  const { displaySearch } = useTopicFunctions();
  const dispatch = useDispatch();
  const resultItemRefs = useRef([]);
  const searchIndex = useSelector(searchStore.selectSearchIndex);
  const searchTerm = useSelector(searchStore.selectSearchTerm);
  const refineIndex = useSelector(searchStore.selectRefineIndex);
  const searchAllResults = useSelector(searchStore.selectAllResults);
  const searchTopicsResults = useSelector(searchStore.selectTopicResults);
  const searchDrugResults = useSelector(searchStore.selectDrugResults);
  const sortResults = useSelector(searchStore.selectSortResults);
  const searchFacets = useSelector(searchStore.selectFacets);
  const refineFacet = useSelector(searchStore.selectRefineFacet);
  const currentPage = useSelector(searchStore.selectCurrentPage);
  const paginatePage = useSelector(searchStore.selectPaginatePage);

  const [facetAllResults, setFacetAllResults] = useState('');
  const [facetTopicResults, setFacetTopicResults] = useState('');
  const [facetDrugResults, setFacetDrugResults] = useState('');
  const [allKeywords, setAllKeywords] = useState('');

  const [withinSearchVal, setWithinSearchVal] = useState('');
  const [homeLinkClass, setHomeLinkClass] = useState('')
  const [drugLinkClass, setDrugLinkClass] = useState('');
  const [menuLinkClass, setMenuLinkClass] = useState('');
  const [searchingTabClass, setSearchingTabClass] = useState('active');
  const [homeTabClass, setHomeTabClass] = useState('');
  const [menuTabClass, setMenuTabClass] = useState('');
  const [drugTabClass, setDrugTabClass] = useState('');
  const [gSnapshot, setGSnapshot] = useState(0);
  const [tSnapshot, setTSnapshot] = useState(0);
  const [snapshotReady, setSnapshotReady] = useState(0);

  const [resultsF, setResultsF] = useState([]);
  const [genSyn, setGenSyn] = useState('');
  const [synKy, setSynKy] = useState('');
  const [synArray, setSynArray] = useState([]);
  const [btnBackResultsShow, setBtnBackResultsShow] = useState(true);
  const [drugTabShow, setDrugTabShow] = useState(true);
  const [shouldGoBack, setShouldGoBack] = useState(false);
  const userId = "guest";
  let filteredArr = [];

  const glOnClickHandler = (glName) => {

    dispatch(searchStore.setRefineFacet(glName));
    // localStorage.setItem('selectedGuideline', glName);
    // sessionStorage.setItem('selectedGuideline', glName);
    // setWithinSearchVal('');
    // tg(glName);

    // if (filteredArr.length > 0) {
    //   submitFilterSearch();
    // } else {
    //   const searchParams = new URLSearchParams(search);
    //   const urlGuideline = searchParams.get('guideline');
    //   if(window.Microsoft) {
    //     let initTG = setInterval(function(){
    //       try{
    //         tgg = window.tgg;
    //         clearInterval(initTG);
    //         tg(urlGuideline);
    //       }catch(e){
    //       }
    //     },intVariable);
    //   }
    //   else {
    //     tg(urlGuideline);
    //   }
    // }

    const newURL = `${window.location.href}&guideline=${encodeURIComponent(glName)}`;
    window.history.replaceState(null, null, newURL);
  }

  const getDocPath = (docId) => {
    return GetTggPath({ i: docId }).i;
  };


  const getTopicTitle = (topicId) => {
    try {
      return findNestedObj(tgg.topics, 'i', topicId).s;
    } catch (error) {
      return '-Topic Title-';
    }

  }

  const GetTitleGuideline = (titleId) => {
    // console.log(titleId);
    const titlePath = findPath(tgg.topics, 'i', titleId.i);
    // console.log(titlePath);
    return { g: titlePath[0] };

  };

  const getTitlePath = (titleId) => {
    // console.log(titleId);
    const titlePath = findPath(tgg.topics, 'i', titleId);
    // console.log(titlePath);
    return titlePath[1];

  };

  const GetTggGuideline = (val) => {
    const guidelinePath = findPath(tgg.guidelines, 'i', val.i);
    return { g: guidelinePath[0] };
  }

  const GetTggPath = (val) => {
    const filePath = findPath(tgg.guidelines, 'i', val.i);
    return { i: filePath[1] };
  }



  function findNestedObj(obj, key, val) {
    if (obj[key] === val) {
      return obj;
    }

    for (let k in obj) {
      if (obj.hasOwnProperty(k) && typeof obj[k] === 'object') {
        let nestedObj = findNestedObj(obj[k], key, val);
        if (nestedObj) {
          return nestedObj;
        }
      }
    }

    return null;
  }

  function findPath(obj, key, val, path = []) {
    if (obj[key] === val) {
      return path.concat(key);
    }

    for (let k in obj) {
      if (obj.hasOwnProperty(k) && typeof obj[k] === 'object') {
        let nestedPath = findPath(obj[k], key, val, path.concat(k));
        if (nestedPath) {
          return nestedPath;
        }
      }
    }

    return null;
  }

  const getGuidelinesFacet = async (facets, caller = "unknown") => {
    console.time("buildFacets-" + caller);
    const facetContent = [];
    // console.log(facets);
    if (facets.guideline) {
      let newArr = [];
      //   const facets = {guideline:{}};
      //   results.map((result) => {
      //     const group = result.g;
      //     if (!facets.guideline[group]) {
      //       facets.guideline[group] = 0;
      //     }
      //     facets.guideline[group]++;
      //   });


      newArr = Object.fromEntries( //sort facet based on the number of results per guideline
        Object.entries(facets.guideline).sort(function (a, b) {
          return b[1] - a[1];
        })
      );
      let facetArray = Object.entries(newArr);
      // facetArray = facetArray.filter(item => item[0] !=="Other");
      // console.log(facetArray);  

      facetArray.map((_, firstIdx) => {
        let content = facetArray[firstIdx].map((_, secondIdx) => {
          if (secondIdx !== 1) {
            const glName = facetArray[firstIdx][secondIdx].replace(/_/g, ' ');
            // console.log(glName);
            const propStr = glName.toLowerCase();
            return (
              <span key={firstIdx + secondIdx} className="facet-item">
                <span className={`dotIcon ${propStr.replace(/ /g, '')}`}></span><a href="#" className="guidelineTitleForFilterSearch gl-value" onClick={() => glOnClickHandler(glName)}><span className="gl-text">{glName}</span></a>
              </span>
            );
          } else {
            return <span className="facet-item-count" key={firstIdx + secondIdx}>
              {' '}
              <strong className="marginLeft3px badge">{facetArray[firstIdx][secondIdx]}</strong>
            </span>;
          }
        })

        return facetContent.push(<li key={firstIdx} data-key={firstIdx} className="facet-group">{content}</li>)
      });
    } else {
      facetContent.push(<li key={0} data-key={0} className="facet-group"></li>)
    }

    // setFacetContentHTML(facetContent);
    // console.log(facetContent);
    console.timeEnd("buildFacets-" + caller);
    return facetContent
  }

  const getRankPoints = (resultsArr, genSynonyms, synKey) => {
    // return; // TODO: remove this function
    // console.log(resultsArr, genSynonyms, synKey);
    let searchArray = [];
    if (genSynonyms.length > 0) { //if search input has synonyms
      searchArray = genSynonyms;
    } else { //if search input has no synonyms
      let headSearch = searchIndex.split(" ");
      if (headSearch.length === 1) { //if searchKeyword is one word only, it will check for the last letter if it has 's'
        headSearch.map(hKey => {
          if (hKey.charAt(hKey.length - 1) === 's')
            headSearch.push(hKey.substr(0, hKey.length - 1));
          else
            headSearch.push(hKey + "s");
        });
      }
      searchArray = headSearch;
    }
    // console.log(resultsArr);
    for (let i = 0; i < resultsArr.length; i++) {
      resultsArr[i].ra = 0;
      let getTopic = getTopicTitle(resultsArr[i].t);
      let getSubTopic = resultsArr[i].s;
      let getGuidelines = findPath(tgg, 'i', resultsArr[i].i)[1].replace(/_/g, ' ').toLowerCase();
      let getDrug = resultsArr[i].d, refineSearching = "";
      if (withinSearchVal.length > 0)
        refineSearching = withinSearchVal.toLowerCase();
      else
        refineSearching = null;

      // +1 pt per synKey found in k / synonyms index
      if (synKey !== 0 && resultsArr[i].hasOwnProperty('k')) {
        let kArr = resultsArr[i].k;
        kArr.map(synkKey => {
          if (synKey === synkKey)
            resultsArr[i].ra += 1;
        });
      }

      // +15 per keyword found in di 
      if (resultsArr[i].hasOwnProperty('d')) {
        for (let drugIndex in getDrug) {
          if (getDrug[drugIndex].indexOf(searchIndex.toLowerCase()) > -1) {
            resultsArr[i].ra += 15;
          }
          if (refineSearching !== null) {
            if (getDrug[drugIndex].indexOf(refineSearching) === 0)
              resultsArr[i].ra += 15;
          }
        }
      }

      /* TOPIC RANKING */
      searchArray.map(srchKey => {
        let newsrchKey = srchKey.trim();
        let regExpstr;
        if (newsrchKey.length <= 4)
          regExpstr = new RegExp(newsrchKey, "g"); //search keyword that are four char or less, case sensitive
        else if (newsrchKey.length >= 5)
          regExpstr = new RegExp(newsrchKey, "gi"); //search keyword that are more than four char, case insensitive

        //+10 on existing keyword on topic; add: +10 if the keyword is the first word, +5 if the position of keyword >= 1, +2 if the position of keyword >= 4
        let topicPosition = getTopic.search(regExpstr);
        if (topicPosition > -1) {
          resultsArr[i].ra += 10;
          if (topicPosition === 0)
            resultsArr[i].ra += 15;
          else if (topicPosition <= 3)
            resultsArr[i].ra += 10;
          else if (topicPosition >= 4)
            resultsArr[i].ra += 5;
        }
      });

      //Updated Subtopic ranking
      if (getSubTopic.includes(' ')) {
        let getsubArr = getSubTopic.split(' ');
        getsubArr.forEach(subElem => {
          for (let key = 0; key < searchArray.length; key++) {
            let newsrchKey = searchArray[key].trim();
            if (subElem.toLowerCase() === newsrchKey.toLowerCase()) {
              resultsArr[i].ra += 10;
            }

          }
        });
      } else {
        for (let key = 0; key < searchArray.length; key++) {
          let newsrchKey = searchArray[key].trim();
          let regExpstr;
          if (newsrchKey.length <= 4)
            regExpstr = new RegExp(newsrchKey, "g"); //search keyword that are four char or less, case sensitive
          else if (newsrchKey.length >= 5)
            regExpstr = new RegExp(newsrchKey, "gi"); //search keyword that are more than four char, case insensitive

          if (getSubTopic === regExpstr) {
            resultsArr[i].ra += 10;
          }
        }
      }

      // +2 pt per keyword found in guidelines
      searchArray.map(srchKey => {
        if (getGuidelines.indexOf(srchKey.toLowerCase()) !== -1) {
          resultsArr[i].ra += 2;
        }
        if (refineSearching !== null) {
          if (getGuidelines.indexOf(refineSearching) !== -1)
            resultsArr[i].ra += 2;
        }
      });
    }
    resultsArr.sort((a, b) => {
      return b.ra - a.ra;
    });
  }

  const setStateOnRedirect = (guidelineName, guidelineId) => {
    const IdArr = guidelineId.split("/");
    const newGL = guidelineName.replace(/_/g, ' ');
    const openSection = IdArr.slice(0, 2).join('/');

    const data = {
      ditaPath: `${guidelineName.replace(/ /g, '_')}/`,
      queryString: {
        guidelinePage: encodeURIComponent(newGL),
        etgAccess: "true"
      },
      displayBanner: true,
      openSection
    };
    dispatch(updateState(data));
  }

  const PaginateMenu = () => {
    // const searchIndex = localStorage.getItem('headerSearch').trim();
    const checkGuideline = localStorage.getItem('selectedGuideline');
    const newSearchParams = new URLSearchParams(window.location.search);
    let topicURL = "";
    setSearchingTabClass('');
    setHomeLinkClass('');
    setDrugLinkClass('');
    setMenuLinkClass('activecount');
    setHomeTabClass('');
    setMenuTabClass('active');
    setDrugTabClass('');

    if (newSearchParams.has('guideline'))
      topicURL = `?appendedinputbuttons=${searchIndex}&guideline=${checkGuideline}#topic`;
    else
      topicURL = `?appendedinputbuttons=${searchIndex}#topic`;
    window.history.replaceState(null, null, topicURL);
  }

  const PaginateHome = () => {
    setSearchingTabClass('');
    setHomeLinkClass('activecount');
    setDrugLinkClass('');
    setMenuLinkClass('');
    setHomeTabClass('active');
    setMenuTabClass('');
    setDrugTabClass('');
  }

  const PaginateSearch = () => {
    $(".loader").fadeIn("fast");
    setSearchingTabClass('active');
    setHomeLinkClass('');
    setDrugLinkClass('');
    setMenuLinkClass('');
    setHomeTabClass('');
    setMenuTabClass('');
    setDrugTabClass('');

    if (searchTerm.length === 0) {
      $(".loader").fadeOut("fast");
    }

  }

  const PaginateDrug = () => {
    let topicURL;
    // const searchIndex = localStorage.getItem('headerSearch').trim();
    const checkGuideline = localStorage.getItem('selectedGuideline');
    const newSearchParams = new URLSearchParams(window.location.search);
    setSearchingTabClass('');
    setHomeLinkClass('');
    setDrugLinkClass('activecount');
    setMenuLinkClass('');
    setHomeTabClass('');
    setMenuTabClass('');
    setDrugTabClass('active');
    if (newSearchParams.has('guideline'))
      topicURL = `?appendedinputbuttons=${encodeURIComponent(searchIndex)}&guideline=${checkGuideline}#drugindication`;
    else
      topicURL = `?appendedinputbuttons=${encodeURIComponent(searchIndex)}#drugindication`;
    window.history.replaceState(null, null, topicURL);
  }



  const SearchTGSynonyms = (searchText) => {
    searchText = (searchText.charAt(searchText.length - 1) === '*') ? searchText.slice(0, -1) : searchText; // remove asterisk
    const lowerCaseSearchText = searchText.toLowerCase();
    return tgg.synonyms.rows.reduce((indices, item, index) => {
      const keywords = item[0].split(',').map(keyword => keyword.trim().toLowerCase());
      if (keywords.includes(lowerCaseSearchText)) {
        // console.log(keywords,lowerCaseSearchText,index);
        indices.push(index);
      }
      return indices;
    }, []);
  };

  const pluralize = (word) => {
    if (word.match(/(s|x|z|ch|sh)$/i)) {
      return word + 'es';
    } else if (word.match(/[^aeiou]y$/i)) {
      return word.replace(/y$/i, 'ies');
    } else {
      return word + 's';
    }
  };

  const singularize = (word) => {
    if (word.match(/(s|x|z|ch|sh)es$/i)) {
      return word.replace(/es$/i, '');
    } else if (word.match(/[^aeiou]ies$/i)) {
      return word.replace(/ies$/i, 'y');
    } else {
      return word.replace(/s$/i, '');
    }
  };

  const removeStopWords = (query) => {
    const stopWords = ["a", "about", "above", "after", "again", "against", "ain", "all", "am", "an", "and", "any", "are", "aren", "aren't", "as", "at", "be", "because", "been", "before", "being", "below", "between", "both", "but", "by", "can", "couldn", "couldn't", "d", "did", "didn", "didn't", "do", "does", "doesn", "doesn't", "doing", "don", "don't", "down", "during"];
    const words = query.split(' ').filter((word) => !stopWords.includes(word.toLowerCase()));
    return words.join(' ');
  };

  const XPathXpress = (flattenedKeywords, synIndex, topicSearchResults = true) => {

    // const treatedWords = flattenedKeywords.map((word)=>{
    //   return `((contains(|key|, "${pluralize(word)}") or contains(|key|, "${singularize(word)}"))`;
    // });
    // const batchWords = `(${treatedWords.join(') and (')}))`;
    // console.log(batchWords,flattenedKeywords);

    // no need to pluralize.... since defiant uses contains and would find es and s... we need to singularize though... TBD...

    const treatedWords = flattenedKeywords.map((word) => {
      return `contains(|key|, "${word}")`;
    });
    const batchWords = `${treatedWords.join(' and ')}`;
    // console.log(batchWords,flattenedKeywords);

    const searchKeys = ["s"];


    const batchXpression = searchKeys.map((searchKey, index) => {
      return `${batchWords.replace(/\|key\|/g, searchKey)}`;
    });

    if (!topicSearchResults) {
      const drugXpress = XPathDrugs(flattenedKeywords)
        .replace(/\/\/\*\[/g, "(")
        .replace(/\)\]/g, "))")
        ;
      batchXpression.push(drugXpress);
      // console.log(topicSearchResults,batchXpression,drugXpress);
    }


    const combinedBatchExpression = `${batchXpression.join(' or ')}`;
    // console.log(topicSearchResults,combinedBatchExpression);

    return `//*[${combinedBatchExpression}]`;

    // const searhXpression = searchKeys.map((searchKey,index)=>{
    //   const expressions = flattenedKeywords.map((word) => `contains(${searchKey}, "${word}")`);
    //   return `(${expressions.join(' and ')})`;
    // });
    // return `//*[${searhXpression.join(' or ')}]`;

    // const expressions = flattenedKeywords.map((word) => `contains(s, "${word}") or contains(d, "${word}")`);
    // return `//*[${expressions.join(' or ')}]`;
  };


  // const xPath = '//*[(contains(s, "paracetamol") and contains(s, "oral") and contains(s, "nonsteroidal") and contains(s, "anti-inflammatory") and contains(s, "drugs"))]'
  // console.time("defSnapshot");
  // const defSnapshot = defiant.getSnapshot(tgg.guidelines);
  // const snapResults = defiant.search(defSnapshot,xPath)
  // console.timeEnd("defSnapshot");
  // console.log(snapResults);
  // console.time("defSearch");
  // const results = defiant.search(tgg.guidelines,xPath);
  // console.timeEnd("defSearch");
  // console.log(results);


  const XPathDrugs = (flattenedKeywords) => {
    const expressions = flattenedKeywords.map((word) => `contains(d, "${word}")`);
    // TODO: determine difference between and & or
    return `//*[${expressions.join(' and ')}]`;
  };

  const XPathSynonyms = (k) => {
    if (k.length === 0) return "";
    let kArray = [];
    if (k.length === 1)
      kArray = k[0];
    else {
      try {
        kArray = k.split(',');
      } catch (error) {
        console.log(error);
        kArray = [0];
      }
    }
    const kXpression = k.map((n) => {
      return `contains(k, ",${n},")`;
    });
    return ` or ${kXpression.join(' or ')}`;
  };

  const SearchTGDrugs = (obj, searchValue, synIndex) => { // NOT USED with xPath
    const drugResults = [];
    const keywords = searchValue.toLowerCase().split(' ');
    synIndex.map(index => {
      const synonyms = tgg.synonyms.rows[index][0].toLowerCase().split(', ');
      keywords.push(...synonyms);
      // keywords.push('aspirin');
    });

    const flattenedKeywords = [].concat(...keywords);
    for (const key in obj) {
      // console.log(key);
      if (typeof obj[key] === 'object' && key !== 'd') {
        const nestedResults = SearchTGDrugs(obj[key], searchValue, synIndex);
        drugResults.push(...nestedResults);
      } else if (key === 'd') {
        for (const drug in obj[key]) {
          const drugValue = obj[key][drug].toString().split(" - ")[0].toLowerCase();
          const drugWordCount = flattenedKeywords.filter((keyword) => drugValue.includes(keyword));
          if (drugWordCount.length > 0) {
            drugResults.push(obj);
          } else {
            delete obj[key][drug];
          }
        }
      }
    }
    return drugResults;
  };

  /*DrugXPath: use defiant start-with to find searchIndex
   * Doesnt need to pluralize or singularize
   * Does not need ranking 
   */
  const DrugXPath = async (searchIndex, synIndex) => {
    console.time(">>>DrugXPath");
    const searchKeyword = (searchIndex.charAt(searchIndex.length - 1) === '*') ? searchIndex.slice(0, -1) : searchIndex;
    const keywords = removeStopWords(searchKeyword).toLowerCase().split(' ');
    synIndex.map(index => {
      const synonyms = tgg.synonyms.rows[index][0].toLowerCase().split(', ');
      keywords.push(...synonyms);
      // keywords.push('aspirin');
    });

    const flattenedKeywords = [].concat(...keywords);
    let xPathXpress = XPathDrugs(flattenedKeywords);
    // console.log(xPathXpress);
    console.time("Drugs:defiantSearch");
    let searchSnapshot = (refineIndex.length > 0) ? searchAllResults : gSnapshot;
    const dResults = defiant.search(searchSnapshot, xPathXpress);
    console.timeEnd("Drugs:defiantSearch");
    // console.log(dResults);
    const matchResults = JSON.parse(JSON.stringify(dResults)) // copy object
      .filter((result) => {
        if (result.s === undefined) return false;

        if (result.g === undefined) {
          result.g = GetTggGuideline(result).g.replace(/_/g, ' ');
        }

        for (const drug in result.d) {
          const drugValue = result.d[drug].toString().split(" - ")[0].toLowerCase();
          const drugWordCount = flattenedKeywords.filter((keyword) => drugValue.includes(keyword));
          if (drugWordCount.length === 0) {
            delete result.d[drug];
          }
        }
        try {
          if (Object.keys(result.d).length === 0) return false;

        } catch (error) {
          return false;
        }
        return true;
      });
    // console.log(matchResults);
    console.timeEnd(">>>DrugXPath");

    const expandedResults = matchResults.flatMap((result) => {
      return Object.keys(result.d).map((key, index) => {
        let newResult = JSON.parse(JSON.stringify(result));
        newResult.d = { [key]: newResult.d[key] };
        return newResult;
      });
    });
    // console.log(expandedResults);
    return expandedResults;
  };

  const SortByResults = (dispatch, searchStore, results) => {

    let sortBy = parseInt(sortResults);
    // console.log(sortBy);

    // console.log(results,sortBy,sortResults);
    Object.keys(results).map((key) => {
      if (sortBy === 0) { // relevance for all results only
        // console.log(key);
        sortBy = 1;
      }
      switch (sortBy) {
        case 0: {
          // console.log("sort by relevance");
          results[key].sort((a, b) => {
            return a.r.to < b.r.to ? 1 : -1;
          });

          break;
        }
        case 1: {
          // console.log("sort by title asc");
          results[key].sort((a, b) => {
            let aValue = a.s;
            let bValue = b.s;
            if (key === "drugs") {
              aValue = a.d[Object.keys(a.d)[0]];
              bValue = b.d[Object.keys(b.d)[0]];
            }
            return bValue < aValue ? 1 : -1;
          });
          break;
        }
        case 2: {
          // console.log("sort by title desc");
          results[key].sort((a, b) => {
            let aValue = a.s;
            let bValue = b.s;
            if (key === "drugs") {
              aValue = a.d[Object.keys(a.d)[0]];
              bValue = b.d[Object.keys(b.d)[0]];
            }
            return aValue < bValue ? 1 : -1;
          });
          break;
        }
      }

    });

    // console.log(results);
    dispatch(searchStore.setResults({
      allResults: results.all,
      topicResults: results.topics,
      drugResults: results.drugs
    }));
    return results;

  }

  const SearchXPath = async (searchIndex, synIndex, topicSearchResults = true) => {
    console.time(">>>SearchXPath" + ((topicSearchResults) ? '-topic' : '-all'));
    // console.log(searchIndex);
    const searchKeyword = (searchIndex.charAt(searchIndex.length - 1) === '*') ? searchIndex.slice(0, -1) : searchIndex;
    const keywords = removeStopWords(searchKeyword).toLowerCase().split(' ').map((word) => [word]);
    // TODO: add singularize and pluralize...
    const rScores = { d: 15, s: 10, t: 5, k: 3, g: 1 };
    let flattenedKeywords = [].concat(...keywords);
    let xPathXpress = "//*[]";
    if (searchIndex.length >= 3)
      xPathXpress = XPathXpress(flattenedKeywords, synIndex, topicSearchResults);
    // console.log(xPathXpress);
    const kXPress = XPathSynonyms(synIndex);
    if (kXPress.length > 0) {
      if (searchIndex.length >= 4) {
        xPathXpress = xPathXpress.replace("]", kXPress + "]");
      } else {
        xPathXpress = `//*[${kXPress.replace(" or c", "c")}]`;
      }
      // xPathXpress = `//*[${kXPress.replace(" or c","c")}]`;
    }
    // console.log(((topicSearchResults)?"Topics:":"All:")+xPathXpress);
    let searchSnapshot = ((topicSearchResults)) ? tSnapshot : gSnapshot;
    let refineTag = "";
    if (refineIndex.length > 0) {
      searchSnapshot = ((topicSearchResults)) ? searchTopicsResults : searchAllResults;
      refineTag = "-refined";
    }
    // if(refineIndex.length > 0) {
    //   debugger;
    // }
    // console.log(refineTag,searchSnapshot);
    console.time(((topicSearchResults) ? 'Topic' : 'All') + ":defiantSearch" + refineTag);
    const tResults = defiant.search(searchSnapshot, xPathXpress);
    console.timeEnd(((topicSearchResults) ? 'Topic' : 'All') + ":defiantSearch" + refineTag);
    // console.log(xPathXpress,tResults);
    let searchItems = xPathXpress.split("contains(");
    let isKOnly = false;
    if (searchItems.length > 1) {
      if ((searchItems[1].indexOf("k") === 0 && !topicSearchResults)
        || (sortResults !== 0 && !topicSearchResults)) { // 0 is sort by relevance.
        isKOnly = true;
      }
    }
    // console.log(searchItems[1]);
    if (refineIndex.length > 0 || isKOnly) {
      // console.log(tResults);
      return tResults;
    }
        
    // console.log("tResults",tResults,xPathXpress);
    flattenedKeywords.push(...synIndex);
    // console.log("flattenedKeywords",flattenedKeywords);
    const matchResults = JSON.parse(JSON.stringify(tResults)) // copy object
      .filter((result) => {
        if (result.g === undefined) {
          if (result.t !== undefined) {
            result.g = GetTggGuideline(result).g.replace(/_/g, ' ');
          } else {
            result.g = GetTitleGuideline(result).g.replace(/_/g, ' ');
          }
        }

        let tValue = "";
        if (result.t !== undefined) {
          tValue = getTopicTitle(result.t).toLowerCase();
          result.t = getTopicTitle(result.t)
            + "|" + result.t
            + "|" + getTitlePath(result.t);
          // console.log(result.t);
        }

        let dValue = "";
        if (result.d !== undefined) {
          result.d = dValue = JSON.stringify(result.d).toLowerCase();
        }

        const sValue = (result.s || '').toLowerCase();
        const gValue = (result.g || '').toLowerCase();
        const kValue = (result.k || '').toLowerCase().replace(/,/g, " ");
        const stemValue = (searchIndex.charAt(searchIndex.length - 1) === '*') ? true : false;
        // console.log("stemValue",stemValue);
        return flattenedKeywords.some((word) => {
          // console.log(word);
          word = word.toString().toLowerCase();
          const regex = new RegExp(`\\b${word}\\b`, 'i');
          if (regex.test(sValue) || regex.test(dValue) || regex.test(kValue) || regex.test(tValue) || regex.test(gValue) || stemValue) {
            if (!result.r && !topicSearchResults) {
              result.r = { to: 0, d: 0, s: 0, t: 0, k: 0, g: 0 };
            }
            // console.log(JSON.stringify(result));
            Object.keys(result).map((key) => {
              // console.log(key,result[key]);  
              if (result[key] !== ''
                && result[key] !== undefined
                && result[key] !== null
                && !topicSearchResults
                && !(["i", "r", "k"].includes(key))) {

                // console.log(result,key,result[key],word,result[key].indexOf(word));

                const keywordIndex = result[key].toLowerCase().indexOf(word);
                const percentage = (keywordIndex === 0)
                  ? 100
                  : (keywordIndex > 0)
                    ? Math.round((1 - keywordIndex / result[key].length) * 100)
                    : 0;
                result.r[key] = rScores[key] * percentage / 100;
                result.r.to += result.r[key];
                // console.log("key,[result[key],result.r,result.r[key]]",key,result[key],result.r,result.r[key]);

                if (key === "d") {
                  result[key] = JSON.parse(result[key]); // d is already stringified and needs to be parsed.
                  // console.log(result[key]);
                }
              }

              if (key === "k"
                && !topicSearchResults) {
                const k = result[key].split(",");
                const kCount = k.filter((num) => synIndex.includes((parseInt(num) || 0))).length;
                if (kCount > 0) {
                  result.r[key] = rScores[key] * kCount;
                  result.r.to += result.r[key];
                }
              }
              // console.log(result.s,result.r);
            });
            return true;
          } else return false;
        });
      });

    // console.log("defiant and matched results",tResults,matchResults);
    console.timeEnd(">>>SearchXPath" + ((topicSearchResults) ? '-topic' : '-all'));
    // console.log("matchResults",JSON.stringify(matchResults));
    return matchResults;

  }

  const SearchTGIndex = (obj, searchValue, synIndex) => {
    const tggResults = [];
    const raScores = { d: 15, s: 10, t: 5, k: 3, g: 1 };
    const keywords = searchValue.toLowerCase().split(' ').map((word) => [word, pluralize(word), singularize(word)]);
    const flattenedKeywords = [].concat(...keywords);
    for (const key in obj) {
      if (typeof obj[key] === 'object') {
        const nestedResults = SearchTGIndex(obj[key], searchValue, synIndex);
        tggResults.push(...nestedResults);
      } else if (key === 's' || key === 'd' || key === 't' || key === 'g') {
        let value = ((key === 't') ? getTopicTitle(obj.t) :
          obj[key]).toString().toLowerCase();
        if (key === 't') { obj.t = value + "|" + obj.t + "|" + getTitlePath(obj.t); }
        // value = JSON.stringify(value);
        // if(obj.t!==undefined && obj.g===undefined){
        //   obj.g = GetTggGuideline(obj).g;

        // }
        const wordCount = flattenedKeywords.filter((keyword) => value.includes(keyword)).length;
        if (wordCount > 0) {
          if (!obj.r) {
            obj.r = { to: 0, d: 0, s: 0, t: 0, k: 0, g: 0 };
          }
          if (key === 'd') {
            let maxPercentage = 0;
            console.log(obj.d); //TODO...rank does not apply.
            for (const item of obj.d) {
              const itemValue = item.toString().toLowerCase();
              const itemWordCount = flattenedKeywords.filter((keyword) => itemValue.includes(keyword)).length;
              if (itemWordCount > 0) {
                const itemKeywordIndex = itemValue.indexOf(flattenedKeywords.find((keyword) => itemValue.includes(keyword)));
                const itemPercentage = (itemKeywordIndex === 0) ? 100 :
                  (itemKeywordIndex > 0) ? Math.round((1 - itemKeywordIndex / itemValue.length) * 100) : 0;
                if (itemPercentage > maxPercentage) {
                  maxPercentage = itemPercentage;
                }
              }
            }
            obj.r[key] = raScores[key] * maxPercentage / 100;
            obj.r.to += obj.r[key];
            if (tggResults.length === 0)
              tggResults.push(obj);
          } else {
            const keywordIndex = value.indexOf(flattenedKeywords.find((keyword) => value.includes(keyword)));
            const percentage = (keywordIndex === 0) ? 100 :
              (keywordIndex > 0) ? Math.round((1 - keywordIndex / value.length) * 100) : 0;

            obj.r[key] = raScores[key] * wordCount * percentage / 100;
            obj.r.to += obj.r[key];

            // Add Guideline name  and rank to result
            if (obj.g === undefined) {
              if (obj.t !== undefined) {
                obj.g = GetTggGuideline(obj).g.replace(/_/g, ' ');
              } else {
                // console.log(obj);
                obj.g = GetTitleGuideline(obj).g.replace(/_/g, ' ');
              }

              const wordCount = flattenedKeywords.filter((keyword) => obj.g.includes(keyword)).length;
              if (wordCount > 0) {
                const keywordIndex = obj.g.indexOf(flattenedKeywords.find((keyword) => obj.g.includes(keyword)));
                const percentage = (keywordIndex === 0) ? 100 :
                  (keywordIndex > 0) ? Math.round((1 - keywordIndex / obj.g.length) * 100) : 0;
                obj.r.g = raScores.g * wordCount * percentage / 100;
                obj.r.to += obj.r.g;
              }
            }
            if (tggResults.length === 0)
              tggResults.push(obj);
          }
        }
      }
      else if (key === 'k') {
        const k = obj['k'].split(",");
        const kCount = k.filter((num) => synIndex.includes(num)).length;
        if (kCount > 0) {
          obj.r[key] = raScores[key] * kCount;
          obj.r.to += obj.r[key];
          if (tggResults.length === 0)
            tggResults.push(obj);
        }
      }
    }
    return tggResults;
  };

  const tg = async (dispatch, searchStore) => {
    // const searchIndex = searchStore.searchIndex;
    if (searchTerm === null || searchTerm === undefined || searchTerm.length < 3) {
      dispatch(searchStore.resetResults("useEffect-searchTerm"));
      return;
    }


    let synKey = 0;
    let selectedGuideline = "";
    let genSynonyms = [], newResults = [], resultsFound = [], synIndex = [];
    if (sessionStorage.selectedGuideline !== "" || sessionStorage.selectedGuideline !== null)
      selectedGuideline = sessionStorage.selectedGuideline;
    else if (sessionStorage.selectedGuideline !== "" || sessionStorage.selectedGuideline !== null)
      selectedGuideline = localStorage.selectedGuideline;
    else
      selectedGuideline = "";


    let synonymSearchTerm = (refineIndex.length > 0)
      ? refineIndex
      : searchIndex;
    console.time("tggSynonyms" + ((refineIndex.length) ? '-refine' : '-search'));
    synIndex = SearchTGSynonyms(synonymSearchTerm);
    console.timeEnd("tggSynonyms" + ((refineIndex.length) ? '-refine' : '-search'));

    let searchKeyWords = synIndex.map((item) => {
      // console.log(item,tgg.synonyms.rows[item]);
      return tgg.synonyms.rows[item];
    })
    searchKeyWords = searchKeyWords.join(" ").trim();
    searchKeyWords = searchKeyWords + " " + synonymSearchTerm;
    searchKeyWords = searchKeyWords.trim().toLowerCase().split(" ");
    searchKeyWords = searchKeyWords.filter((word) => { word = word.trim(); return word.length > 0; })
    setAllKeywords(searchKeyWords);

    // let objTopics = JSON.parse(JSON.stringify(tgg.topics));
    // const tggTopicResults = SearchTGIndex(objTopics, searchIndex,synIndex);
    // tggTopicResults.sort((a,b) => {
    //     return a.s.toLowerCase()<b.s.toLowerCase()?-1:1;
    // });
    let queryTerm = (refineIndex.length > 0)
      ? refineIndex
      : searchIndex;

    let searchResults = { all: [], topics: [], drugs: [] };
    console.time("SearchTGTopics");
    searchResults.topics = await SearchXPath(queryTerm, synIndex, true)
    console.timeEnd("SearchTGTopics");


    // console.time("SearchTGDrugs");
    const drugsIndex = JSON.parse(JSON.stringify(tgg.guidelines)); // TODO: use tggGuidelineResults after search for d is fixed.
    // const tggDrugResults = SearchTGDrugs(drugsIndex,queryTerm,synIndex);
    // console.log(tggDrugResults);

    searchResults.drugs = await DrugXPath(queryTerm, synIndex)
    console.time("SearchTGGuidelines");
    console.log(queryTerm, synIndex, false);
    searchResults.all = await SearchXPath(queryTerm, synIndex, false)
    console.timeEnd("SearchTGGuidelines");
    $(".loader").fadeOut("fast");
    SortByResults(dispatch, searchStore, searchResults);

    // if(selectedGuideline !== null && (urlGuideline !== null || urlGuideline !== 'null')) {
    //   // getTG(resultsFound, genSynonyms, synKey);
    //   submitFilterSearch(tggGuidelineResults, synIndex, synKey);
    // } else if(selectedGuideline === null && (urlGuideline === null || urlGuideline === 'null')) {
    //   // console.log(resultsFound,genSynonyms,synKey);

    //   getTG(tggGuidelineResults);
    //   getTopicTG(tggTopicResults);
    //   getDrugIndTG(tggDrugResults);
    // }

    setResultsF(0);
    setGenSyn(synIndex);
    setSynKy(synKey);
  };

  const submitFilterSearch = (resultsFound, genSynonyms, synKey) => {
    // setGlContentHTML([]);
    // clearProcessedResults();
    const searchHead = document.getElementById('searchString').value, refineSearch = document.getElementById('withinSearchInputButtons').value.trim();
    let selectedGuideline = "";
    if (sessionStorage.selectedGuideline !== "null" || sessionStorage.selectedGuideline !== null) {
      selectedGuideline = sessionStorage.selectedGuideline;
    } else {
      selectedGuideline = localStorage.selectedGuideline;
    }

    const filterText = localStorage.getItem('filterText');
    let filterCheck = [], filterCtr = 0;
    const resultsArr = resultsFound;
    const contentHTML = [];

    if (!searchHead && !refineSearch) {
      alert('Please enter a search term.');
    } else {
      if (refineSearch) {
        if (!searchIndex) {
          dispatch(searchStore.setSearchIndex(refineSearch));
          submitform(refineSearch);
        } else {
          if (resultsArr.length > 0) {
            localStorage.setItem('filterText', refineSearch);
            getRankPoints(resultsArr, genSynonyms, synKey);
            resultsArr.map((_, index) => {
              if (index > 10) return;
              const getTopicName = getTopicTitle(resultsArr[index].t).toLowerCase().replace(/,|:|\(|\)/g, '')
              const getSubTopic = resultsArr[index].s.toLowerCase();

              if (getTopicName.indexOf(refineSearch.toLowerCase()) !== -1 || getSubTopic.indexOf(refineSearch.toLowerCase()) !== -1) {
                const topicFilePath = getTitlePath(resultsArr[index].t);
                const listId = GetTggPath(resultsArr[index]).i.split("/");

                contentHTML.push(
                  <li key={index} data-key={index} className="resultitem" data-id={`${listId[0]}/${listId[1]}`} ref={resultItemRefs.current[index]}>
                    <h5 className="searchContentH">
                      <HashLink
                        to={`/viewTopic?etgAccess=true&guidelinePage=${encodeURIComponent(GetTggGuideline(resultsArr[index]).g.replace(/_/g, ' '))}&navigateTopic=${topicFilePath.replace(/ /g, '-')}`}
                        onClick={() => setStateOnRedirect(GetTggGuideline(resultsArr[index]).g.replace(/_/g, ' '), resultsArr[index].i)}
                      >
                        {resultsArr[index].s}
                      </HashLink>
                    </h5>
                    <p className="searchContentP">
                      <strong>Guidelines <a href="#" title={JSON.stringify(resultsArr[index].r)}>:</a> </strong>
                      <span id="currentGL">{GetTggGuideline(resultsArr[index]).g.replace(/_/g, ' ')}</span>
                      <strong>  Topic : </strong>
                      <HashLink
                        to={`/viewTopic?etgAccess=true&guidelinePage=${encodeURIComponent(GetTggGuideline(resultsArr[index]).g.replace(/_/g, ' '))}&navigateTopic=${topicFilePath.replace(/ /g, '-')}.dita#toc|0`}
                        onClick={() => setStateOnRedirect(GetTggGuideline(resultsArr[index]).g.replace(/_/g, ' '), getTopicTitle(resultsArr[index].t))}
                      >
                        {resultsArr[index].s}
                      </HashLink>
                    </p>
                  </li>
                );

                filterCtr++
                filteredArr.push(resultsArr[index]);
              }
            });
            // getGuidelinesFacet(filteredArr);
            // getTopicTG(filteredArr);
          }
        }
      } else {
        const refineSearchFilter = [];
        if (refineSearch === '' && selectedGuideline === null) {
          alert('Please enter a search term.');
          if (filterText !== '') {
            setWithinSearchVal(localStorage.getItem('filterText'));
          }
          return;
        } else if (refineSearch === '' && selectedGuideline !== null) {
          if (filteredArr.length === 0) {
            filterCheck = resultsArr;
          } else {
            setWithinSearchVal(localStorage.getItem('filterText'));
            filterCheck = filteredArr;
          }

          const facetListItems = document.querySelectorAll('#facetList li');
          facetListItems.forEach(function (item) {
            const testStr = item.textContent.replace(/[0-9]/g, '').trim();
            if (testStr === sessionStorage.selectedGuideline) {
              const link = item.querySelector('a');
              link.classList.add('active-gl');
            } else {
              const link = item.querySelector('a');
              link.classList.remove('active-gl');
            }
          });

          getRankPoints(filterCheck, genSynonyms, synKey);

          filterCheck.map((_, index) => {
            if (index > 10) return;
            const GLreplaced = GetTggGuideline(filterCheck[index]).g.replace(/_/g, ' ');
            if ((GLreplaced.toLowerCase().indexOf(selectedGuideline.toLowerCase()) !== -1)) {
              const getTopicName = getTopicTitle(filterCheck[index].t).toLowerCase().replace(/,|:|\(|\)/g, '');
              const getSubTopic = filterCheck[index].s.toLowerCase();

              if (getTopicName.indexOf(refineSearch.toLowerCase()) !== -1 || getSubTopic.indexOf(refineSearch.toLowerCase()) !== -1) {
                refineSearchFilter.push(filterCheck[index]);
                const topicString = getTopicName.replace(/ /g, '-');
                const topicFilePath = getTitlePath(filterCheck[index].t);
                const listId = getDocPath(filterCheck[index].i).split("/");
                contentHTML.push(
                  <li key={index} data-key={index} className="resultitem" data-id={`${listId[0]}/${listId[1]}`} ref={resultItemRefs.current[index]}>
                    <h5 className="searchContentH">
                      <HashLink
                        to={`/viewTopic?etgAccess=true&guidelinePage=${encodeURIComponent(GLreplaced)}&topicfile=${topicFilePath.replace(/ /g, '-')}`}
                        onClick={() => setStateOnRedirect(GLreplaced, getDocPath(filterCheck[index].i))}
                      >
                        {filterCheck[index].s}
                      </HashLink>
                    </h5>
                    <p className="searchContentP">
                      <strong>Guidelines <a href="#" title={JSON.stringify(filterCheck[index].r)}>:</a> </strong>
                      <span id="currentGL">{GLreplaced}</span>
                      <strong>  Topic : </strong>
                      <HashLink
                        to={`/viewTopic?etgAccess=true&guidelinePage=${encodeURIComponent(GLreplaced)}&navigateTopic=${topicFilePath.replace(/ /g, '-')}.dita#toc|0`}
                        onClick={() => setStateOnRedirect(GLreplaced, getDocPath(filterCheck[index].i))}
                      >
                        {getTopicTitle(filterCheck[index].t)}
                      </HashLink>
                    </p>
                  </li>
                );
                filterCtr++;
              }
            }
          });
          // getTopicTG(refineSearchFilter);
        }
      }
      if (filterCtr === 0) {
        // setGlContentHTML([]);
        contentHTML.push(<li key={0} data-key={0} style={{ borderBottom: '0!important' }}>
          <h5 className="searchContentH">No results found.</h5>
        </li>);
      }
      console.log(new Date(), "FILTERED RESULTS - start to load first 10 results");
      // setGlContentHTML(contentHTML);
      // setGlContentHTML(prevState => [...prevState, ...contentHTML]);
      console.log(new Date(), "FILTERED RESULTS - finished to load first 10 results");
      // setTotalResults(filterCtr);
      if (sessionStorage.savedSearch === '1') {
        setBtnBackResultsShow(false);
      }
      else {
        setBtnBackResultsShow(true);
      }
    }

    setDrugTabShow(false);
  }

  const revertURL = () => {
    // const searchIndex = searchStore.searchIndex;
    const revertURL = "?appendedinputbuttons=" + searchIndex;
    window.history.replaceState(null, null, revertURL);
  };

  const goBack = () => {
    sessionStorage.removeItem('selectedGuideline');
    localStorage.removeItem('selectedGuideline');
    localStorage.removeItem('filterText');
    setWithinSearchVal('');
    dispatch(searchStore.setRefineIndex(""));
    dispatch(searchStore.setRefineFacet(""));
  };

  const withinSearchKeyPress = (event) => {
    if (event.charCode === 13) {
      submitFilterSearch(resultsF, genSyn, synKy);
    }
  };

  const filterSearch = () => {
    // setTimeout(() => {
    //   $(window).scroll();
    // }, 100);
    submitFilterSearch(resultsF, genSyn, genSyn);
  }

  const highlightTextWithRegex = (htmlString, textsToHighlight) => {
    // console.time("highlight-results");
    // return htmlString;
    // Escape special characters in each string and join them into a single regex pattern
    // console.log(htmlString,textsToHighlight);
    const escapedTexts = textsToHighlight.map(text => text.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'));
    const regexPattern = `(${escapedTexts.join('|')})`;
    const regex = new RegExp(regexPattern, 'gi');

    // Replace the matched text with <mark> tags
    // return htmlString.replace(regex, '<mark>$1</mark>');
    // console.timeEnd("highlight-results");
    return <span dangerouslySetInnerHTML={{ __html: htmlString.replace(regex, '<mark>$1</mark>') }} />;
  }

  // const clearProcessedResults = () => {
  //   const elements = resultItemRefs.current.map(ref => ReactDOM.findDOMNode(ref.current));
  //   for (let i = 0; i < elements.length; i++) {
  //     const element = elements[i];
  //     if (element) {
  //       const isProcessed = element.classList.value.includes('processed');

  //       if (isProcessed) {
  //         try {
  //           const thirdChild = element.childNodes[2];
  //           element.removeChild(thirdChild);  
  //         } catch (error) {}

  //         element.classList.remove('processed');
  //       }
  //     }
  //   }
  // }

  useEffect(() => {
    // console.log("UE-sortResults");
    console.log(sortResults);

    dispatch(searchStore.setCurrentPage(1));

    const searchResults = {
      all: JSON.parse(JSON.stringify(searchAllResults)),
      topics: JSON.parse(JSON.stringify(searchTopicsResults)),
      drugs: JSON.parse(JSON.stringify(searchDrugResults))
    };
    // console.log(searchResults);
    SortByResults(dispatch, searchStore, searchResults);
  }, [sortResults]);

  useEffect(() => {
    // console.log("UE-[]");
    // nothing else should run until snapshots are created... See console to find out what is running and stop it.
    console.time("Created Snapshots");
    defiant.getSnapshot(tgg.guidelines, (gsnapshot) => {
      setGSnapshot(gsnapshot);
    });
    defiant.getSnapshot(tgg.topics, (tsnapshot) => {
      setTSnapshot(tsnapshot);
    });

    window.addEventListener('message', (event) => {
      const { type } = event.data;

      // if (type === 'initial') {
      //   dispatch(updateState({ medium: 'ReactNative' }));
      // } 
    });
  }, []);



  useEffect(() => {
    // console.log("UE-snapshotReady");
    if (!snapshotReady) {
      if (searchTerm.length > 0) { // reset it to sent if when snapshot is ready.
        dispatch(searchStore.setSearchTerm(""));
      } return;
    }
    // console.log(">>snapShot",snapshotReady);

    // console.log("searchIndex",searchIndex); 
    // console.log("searchTerm",searchTerm); 
    const searchParams = new URLSearchParams(search);
    if (searchParams.has('appendedinputbuttons') || searchParams.has('drugname')) {
      const urlSearch = searchParams.get('appendedinputbuttons') || searchParams.get('drugname') || "";
      console.log(urlSearch);
      if (searchIndex === "") {
        dispatch(searchStore.setSearchIndex(urlSearch));
      }
      if (searchTerm === undefined || searchTerm === "") {
        dispatch(searchStore.setSearchTerm(urlSearch));
      }
    }

    $(".loader").fadeOut("slow"); // to show saved searches.

  }, [snapshotReady]);

  // useEffect(() => {
  //   // console.log("searchAllResults",searchAllResults);
  //   getTG(searchAllResults);
  //   // getGuidelinesFacet(searchAllResults);
  // },[searchAllResults]);

  // useEffect(() => {
  //   getGuidelinesFacet(searchFacets);
  // },[searchFacets]);

  useEffect(() => {
    // console.log("UE-searchTopicsResults");
    if (!snapshotReady) return;

    if (searchDrugResults.length > 0) {
      const drugKey = Object.keys(searchDrugResults[0].d)[0];
      const drugName = searchDrugResults[0].d[drugKey];
    }

    const inAsync = async () => {
      const facetItems = await DefiantFacets(searchTopicsResults).then((facetItems) => {
        // console.log(facetItems,Object.keys(facetItems).length!==0);
        const facetContent = getGuidelinesFacet(facetItems, "searchTopicsResults").then((facetContent) => {
          // console.log("FacetsTopicsResults",facetContent);
          setFacetTopicResults(facetContent);
        });
      });
    }
    inAsync();
  }, [searchTopicsResults]);

  useEffect(() => {
    // console.log("UE-searchDrugResults");
    if (!snapshotReady) return;

    if (searchDrugResults.length > 0) {
      const drugKey = Object.keys(searchDrugResults[0].d)[0];
      const drugName = searchDrugResults[0].d[drugKey];
    }

    const inAsync = async () => {
      const facetItems = await DefiantFacets(searchDrugResults).then((facetItems) => {
        // console.log(facetItems,Object.keys(facetItems).length!==0);
        const facetContent = getGuidelinesFacet(facetItems, "searchDrugResults").then((facetContent) => {
          // console.log("FacetsDrugResults",facetContent);
          setFacetDrugResults(facetContent);
        });
      });
    }
    inAsync();

  }, [searchDrugResults]);

  useEffect(() => {
    // console.log("UE-searchAllResults");
    if (!snapshotReady) return;

    const inAsync = async () => {
      const facetItems = await DefiantFacets(searchAllResults).then((facetItems) => {
        // console.log(facetItems,Object.keys(facetItems).length!==0);
        const facetContent = getGuidelinesFacet(facetItems, "searchAllResults").then((facetContent) => {
          // console.log("FacetsAllResults",facetContent);
          setFacetAllResults(facetContent);
        });
      });
    }
    inAsync();

    // console.log("UF:-searchAllResults",searchAllResults.length);

  }, [searchAllResults]);


  useEffect(() => {
    if (paginatePage === "topics") {
      PaginateMenu();
    } else if (paginatePage === "drugs") {
      PaginateDrug();
    } else if (paginatePage === "search") {
      PaginateSearch();
    } else {
      PaginateHome();
    }
  }, [paginatePage]);

  useEffect(() => {
    // console.log("UE-homeTabClass,menuTabClass,drugTabClass");
    if (!snapshotReady) return;

    if (homeTabClass === "active") {
      dispatch(searchStore.setFacets(facetAllResults));
      // console.log(facetAllResults);
    } else if (menuTabClass === "active") {
      dispatch(searchStore.setFacets(facetTopicResults));
      // console.log(facetTopicResults);
    } else if (drugTabClass === "active") {
      dispatch(searchStore.setFacets(facetDrugResults));
      // console.log(facetDrugResults);
    }

    // console.log("[homeTabClass,menuTabClass,drugTabClass]",homeTabClass,"|",menuTabClass,"|",drugTabClass);
    // console.log("[facetAllResults,facetTopicResults,facetTopicResults]",facetAllResults,"|",facetTopicResults,"|",facetDrugResults);

    // setTimeout(function() {
    //   window.scrollTo({top:100,behavior:'smooth'});
    // }, 100);
    dispatch(searchStore.setCurrentPage(1));
  }, [homeTabClass, menuTabClass, drugTabClass]);

  useEffect(() => {
    // console.log("UE-facetAllResults,facetTopicResults,facetTopicResults");
    if (!snapshotReady) return;

    if (homeTabClass === "active") {
      dispatch(searchStore.setFacets(facetAllResults));
      // console.log(facetAllResults);
    } else if (menuTabClass === "active") {
      dispatch(searchStore.setFacets(facetTopicResults));
      // console.log(facetTopicResults);
    } else if (drugTabClass === "active") {
      dispatch(searchStore.setFacets(facetDrugResults));
      // console.log(facetDrugResults);
    }

    // console.log("[homeTabClass,menuTabClass,drugTabClass]",homeTabClass,"|",menuTabClass,"|",drugTabClass);
    // console.log("[facetAllResults,facetTopicResults,facetTopicResults]",facetAllResults,"|",facetTopicResults,"|",facetDrugResults);

    // setTimeout(function() {
    //   window.scrollTo({top:100,behavior:'smooth'});
    // }, 100);
    dispatch(searchStore.setCurrentPage(1));
  }, [facetAllResults, facetTopicResults, facetTopicResults]);



  const DefiantFacets = async (results) => {

    console.time("buildFacetsDefiant");
    const facets = defiant.getFacets(results, {
      'guideline': { group: '*', key: 'g' }
    });
    if (facets.guideline)
      delete facets.guideline[0];
    // delete facets.guideline.Other;
    // console.log(facets);
    console.timeEnd("buildFacetsDefiant");
    return facets;
  };

  useEffect(() => {
    displaySearch();
  }, []);

  useEffect(() => {
    // console.log("UE-gSnapshot,tSnapshot");
    if (gSnapshot !== 0 && tSnapshot !== 0) {
      // console.log(gSnapshot,tSnapshot);
      console.timeEnd("Created Snapshots");
      setSnapshotReady(1);
    }
  }, [gSnapshot, tSnapshot]);

  useEffect(() => {
    // console.log("UE-searchTerm");
    if (!snapshotReady) return;
    // $(".loader").fadeIn("slow");
    console.log("searchIndex", searchIndex, "/", "searchTerm", searchTerm, "/", "refineIndex", refineIndex);
    // setTotalResults(0);
    // setTopicResults(0);
    // setDrugResults(0);
    // dispatch(searchStore.resetResults("useEffect-searchTerm"));
    // setGlContentHTML('');
    // setTopicGlContentHTML('');
    // setDrugContentHTML('');
    setDrugTabShow(true);
    resultItemRefs.current = [];
    const URLpath = window.location.host;
    $('title').text('Search Results - Therapeutic Guidelines');
    $('#topic-banner').text('Search Results');
    $('#tocbox').css("display", "none");

    const searchParams = new URLSearchParams(search);
    if (searchParams.has('appendedinputbuttons')) {
      const urlSearch = searchParams.get('appendedinputbuttons');
      if (searchIndex === "") {
        dispatch(searchStore.setSearchIndex(urlSearch));
      }
      const urlGuideline = searchParams.get('guideline');
      if (urlSearch !== '' || urlSearch.length > 0 || urlGuideline === null) {
        // localStorage.setItem('headerSearch', urlSearch);
        if (urlGuideline === null || urlGuideline === 'null') {
          localStorage.removeItem('selectedGuideline');
          sessionStorage.removeItem('selectedGuideline');
        } else {
          sessionStorage.setItem('selectedGuideline', urlGuideline);
        }
        // tg(urlGuideline);
        setTimeout(() => {
          tg(dispatch, searchStore)
        }, 1000);
        localStorage.removeItem('filterText');

        $(".loader").fadeOut("slow");
      }
    } else {
      $(".loader").fadeOut("slow"); // to show saved searches.
    }


    const searchKey = (contentResult) => {
      // const searchIndex = searchStore.searchIndex;
      let textToSearch = searchIndex, newContent = "", paragraph = contentResult.toString();
      let refineStr = $('#withinSearchInputButtons').val().trim(), searchString = "";
      let textSplit = searchIndex.split(" ");
      if (synArray.length > 0) {
        for (let saIndex = 0; saIndex < synArray.length; saIndex++) {
          let synTerm = synArray[saIndex].replace(/[.*+?^${}()|[\]\\]/g, "\\$&").trim();
          if (saIndex !== synArray.length - 1)
            searchString += synTerm.replace(/,/g, '') + "|";
          else
            searchString += synTerm.replace(/,/g, '');
        }
      }
      else {
        textSplit.map(tKey => {
          if (tKey.charAt(tKey.length - 1) === 's')
            textSplit.push(tKey.substr(0, tKey.length - 1));
          else
            textSplit.push(tKey + 's');
        });
        for (let tIndex = 0; tIndex < textSplit.length; tIndex++) {
          textToSearch = textSplit[tIndex].replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
          if (tIndex !== textSplit.length - 1)
            searchString += textToSearch + "|";
          else
            searchString += textToSearch;
        }
      }
      if (refineStr.length > 0) {
        refineStr.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        searchString += "|" + refineStr;
      }
      const pattern = new RegExp(searchString, "g");
      newContent = paragraph.replace(pattern, match => `<strong><span style='background-color:yellow'>${match}</span></strong>`);
      return newContent;
    }

    return () => {
      // setTotalResults(0);
      // setTopicResults(0);
      // setDrugResults(0);
      // setGlContentHTML('');
      // setWithinSearchVal('');

      // clearProcessedResults();
      localStorage.removeItem('filterText');
      dispatch(updateState({ cache: {} }));
      dispatch(searchStore.setCurrentPage(1));
    };
  }, [searchTerm]);

  useEffect(() => {
    console.log("refineIndex", refineIndex);

    if (!snapshotReady) return;

    setTimeout(() => {
      tg(dispatch, searchStore)
    }, 1000);

  }, [refineIndex]);

  useEffect(() => {
    // console.log("UE-refineFacet");
    if (!snapshotReady) return;

    if (refineFacet.length === 0) {
      PaginateSearch();
      setTimeout(() => {
        tg(dispatch, searchStore)
      }, 1000);
      // tg(refineFacet);
      return;
    }

    let refineTopics = searchTopicsResults.filter((result) => {
      return result.g === refineFacet;
    });
    let refineDrugs = searchDrugResults.filter((result) => {
      return result.g === refineFacet;
    });
    let refineAll = searchAllResults.filter((result) => {
      return result.g === refineFacet;
    });
    dispatch(searchStore.setResults({ topicResults: refineTopics, drugResults: refineDrugs, allResults: refineAll }));

  }, [refineFacet]);

  useEffect(() => {
    console.log("withinSearchVal, shouldGoBack");
    if (!withinSearchVal && shouldGoBack) {
      const searchParams = new URLSearchParams(search);
      const urlGuideline = searchParams.get('guideline');
      filteredArr = [];
      if (getURL.indexOf("powerappsportals") > -1 || getURL.indexOf("tg.org.au") > -1) {
        let initTG = setInterval(function () {
          try {
            tgg = window.tgg;
            clearInterval(initTG);
            setTimeout(() => {
              tg(dispatch, searchStore)
            }, 1000);
            // tg(urlGuideline);

          } catch (e) {
          }
        }, intVariable);
      }
      else {
        setTimeout(() => {
          tg(dispatch, searchStore)
        }, 1000);
        // tg(urlGuideline);
      }
      // getTG(resultsF, genSyn, synKy);
      // getTopicTG(resultsF);
      revertURL();
      setDrugTabShow(true);
      setShouldGoBack(false);
      const facetListItems = document.querySelectorAll('#facetList li a.active-gl');
      facetListItems.forEach((item) => {
        item.classList.remove('active-gl');
      });
    }
  }, [withinSearchVal, shouldGoBack])

  let showFacets = true;

  // console.log("[lengths of searchAllResults, searchTopicsResults, searchDrugResults ]", searchAllResults.length,searchTopicsResults.length,searchDrugResults.length);
  if (searchAllResults.length === 0 && searchTopicsResults.length === 0 && searchDrugResults.length === 0) {
    showFacets = false;
  }

  let pagingList = searchAllResults;
  if (homeTabClass === "active") {
    pagingList = searchAllResults;
  } else if (menuTabClass === "active") {
    pagingList = searchTopicsResults;
  } else if (drugTabClass === "active") {
    pagingList = searchDrugResults;
  }


  // console.log("[lengths of facetAllResults, facetTopicResults, facetDrugResults ]",facetAllResults.length,facetTopicResults.length,facetDrugResults.length);
  // if(facetAllResults.length===0 && facetTopicResults.length===0 && facetDrugResults.length===0){
  //   showFacets = false;
  // }

  return (
    <div id="tgsearch">
      <Suspense fallback={<div className="loader"></div>}>
        <NavBar push={push} setWithinSearchVal={setWithinSearchVal} setSynArray={setSynArray} />
        <TopicBanner pathname={pathname} search={search} push={push} />
        <SavedSearchModal />
        <div className="container">
          <div className="loader"></div>
          <div className="row container">
            <div className="col-md-4 columnBlockLayout searchContainer">
              <h4 className="section-heading hidden-xs">Sort by</h4>
              <SortResults />
              <h4 className="section-heading hidden-xs">Refine search</h4>
              <div className="form-group">
                <RefineSearch />
                <a className="slider-arrow hide" onClick={(e) => { e.preventDefault() }}><i className="flaticon-double30"></i></a>
                <div className="tree-menu refine-container" id="sidemenu-container">
                  <div id="facet-container" className="facet-container">
                    <h5 className="tgFacetHeader"><strong>View Results by Guidelines</strong></h5>
                    <ul id="facetList" className="list-unstyled refine-search ">
                      {showFacets ? searchFacets : ""}
                    </ul>
                    <div className="row">
                      <div className="borderBottom visible-xs"></div>
                      <div className="col-lg-7 col-sm-7 col-xs-7">
                        <a href="#" title="Save search" data-toggle="modal" data-backdrop="static" data-target="#savSearch" id="saveSearchBtn" className="btn btn-inverse btn-block whiteBgXS2">
                          Save search
                        </a>
                      </div>
                    </div>
                    <div id="recentSearchDiv">
                      <div>
                        <h4 className="section-heading mTop30px">My saved searches</h4>
                        <ul className="recent-searches">
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 columnBlockLayout tabContainer ">
              <h4 className="section-heading noBtmMarging noBtmPadding mTop6px">Search results</h4>
              <div className="row result-container">
                {/* DEKSTOP VIEW */}
                <div className="col-md-12 filter-desktop">
                  <span className="enlarge filter-view">View results by:&nbsp;</span>
                  <span title="Shows any content that includes your search term(s)." onClick={() => PaginateHome()} className="enlarge filter-text"><a data-toggle="tab" href="#home" aria-expanded="true" id="allResultsCtr" className={`search-view-tab allResultsCtr ${homeLinkClass}`}>{`All Results (${searchAllResults.length})`}</a></span>&nbsp;
                  <span title="Shows only the main topic headings that include your search term(s)." onClick={() => PaginateMenu()} className="enlarge filter-text"><a data-toggle="tab" href="#menu1" aria-expanded="true" id="topicResultsCtr" className={`search-view-tab topicResultsCtr ${menuLinkClass}`}>{`Topic Results (${searchTopicsResults.length})`}</a></span>&nbsp;
                  {drugTabShow && <span title="If your search term is a drug in Therapeutic Guidelines, this shows a list of links straight to the full dosing information." onClick={() => PaginateDrug()} className="active enlarge filter-text"><a data-toggle="tab" href="#drug" aria-expanded="true" id="drugIndCtr" className={`search-view-tab drugIndCtr ${drugLinkClass}`}>{`Drug Indication (${searchDrugResults.length})`}</a></span>}
                </div>

                {/* MOBILE VIEW */}
                <div className="filter-mobile">
                  <div title="Shows any content that includes your search term(s)." onClick={() => PaginateHome()} className="enlarge filter-text"><a data-toggle="tab" href="#home" aria-expanded="true" id="allResultsCtr" className={`search-view-tab allResultsCtr ${homeLinkClass}`}>{`All Results (${searchAllResults.length})`}</a></div>&nbsp;
                  <div title="Shows only the main topic headings that include your search term(s)." onClick={() => PaginateMenu()} className="enlarge filter-text"><a data-toggle="tab" href="#menu1" aria-expanded="true" id="topicResultsCtr" className={`search-view-tab topicResultsCtr ${menuLinkClass}`}>{`Topic Results (${searchTopicsResults.length})`}</a></div>&nbsp;
                  {drugTabShow && <div title="If your search term is a drug in Therapeutic Guidelines, this shows a list of links straight to the full dosing information." onClick={() => PaginateDrug()} className="active enlarge filter-text"><a data-toggle="tab" href="#drug" aria-expanded="true" id="drugIndCtr" className={`search-view-tab drugIndCtr ${drugLinkClass}`}>{`Drug Indication (${searchDrugResults.length})`}</a></div>}
                </div>
                {
                  (refineIndex.length > 0
                    || refineFacet.length > 0) && (
                    <div id="btnBackResults" className="col-md-12">
                      <h5 className="text-right">
                        <span>«</span>
                        <a id="returnAllResults" href="#" className="noRPaddingIP" onClick={() => goBack()}>
                          <strong>Back to all results</strong>
                        </a>
                      </h5>
                    </div>
                  )
                }
              </div>
              <div id="searching" className={`tab-pane ${searchingTabClass}`}><span className="dot"></span></div>
              <div id="home" className={`tab-pane ${homeTabClass}`}>
                {(homeTabClass === "active")
                  ? <SearchResultsAll snapshot={gSnapshot} tsnapshot={tSnapshot} results={searchAllResults} highlight={highlightTextWithRegex} allkeywords={allKeywords} />
                  : <NoResults />}
              </div>
              <div id="menu1" className={`tab-pane ${menuTabClass}`}>
                {(menuTabClass === "active")
                  ? <SearchResultsTopics results={searchTopicsResults} snapshot={tSnapshot} gsnapshot={gSnapshot} highlight={highlightTextWithRegex} allkeywords={allKeywords} />
                  : <NoResults />}
              </div>
              {drugTabShow
                && <div id="drug" className={`tab-pane ${drugTabClass}`}>
                  {(drugTabClass === "active")
                    ? <SearchResultsDrugs results={searchDrugResults} snapshot={gSnapshot} tsnapshot={tSnapshot} highlight={highlightTextWithRegex} allkeywords={allKeywords} />
                    : <NoResults />}
                </div>}
            </div>
          </div>
        </div>
        {/*(homeTabClass==="active" 
        && searchAllResults.length>0) */
          1 ? <Paging resultcount={pagingList.length} tab1={homeTabClass} tab2={menuTabClass} tab3={drugTabClass} />
            : ""}
        <div className="modal fade" id="savSearch" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                <h4 className="modal-title">Save search</h4>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <input type="text" name="searchname" id="searchName" placeholder="Enter a name" className="form-control" />
                </div>
              </div>
              <div className="modal-footer saveSearch-footer">
                <input type="button" id="searchOkBtn" name="searchOk" value="Ok" className="btn btn-info borderNone" />
                <input type="button" value="Cancel" id="cancelSaveSearch" data-dismiss="modal" className="btn btn-default noborder" />
              </div>
            </div>
          </div>
        </div>
        <TopicToolsSticky pathname={pathname} />

        {$('.dxp-footer').length === 0 &&
          <>
            {!window.Microsoft && <Footer />}
          </>
        }
      </Suspense>
    </div>
  );
}

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
});

export default React.memo(connect(mapStateToProps, { push })(Search));