import React, { Suspense, useState } from 'react'; 
import $ from 'jquery';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import BsaCalc from './bsaCalc';
import CcbwCalc from './ccbwCalc';
import IbwCalc from './ibwCalc';
import LbwCalc from './lbwCalc';
import GfrCalc from './gfrCalc';
import IdCalc from './idCalc';
import NavBar from '../../components/navBar';
import TopicBanner from '../../components/topicBanner';
import Footer from '../../components/footer';
import { Link, useLocation } from 'react-router-dom';

const Calculators = (props) => {
  const { pathname, search, push } = props;
  const location = useLocation();

  const currentPath = location.pathname.split('/')[2] || 'bsacalc';

  $('title').text('Calculators - Therapeutic Guidelines');

  return (
    <>
      <Suspense fallback={<div className="loader"></div>}>
        <NavBar push={push} />
        <TopicBanner pathname={pathname} search={search} push={push} />
        <div className="container">
          <div className="row calculators">
            <div className="col-xs-12 col-sm-6 col-md-3 col-lg-4 tabLeftContainer">
              <ul className="nav nav-tabs tabs-left">
                
                <li className={currentPath === "bsacalc" ? 'active' : ''}>
                  <Link to="/calculator/bsacalc">Body surface area calculator</Link>
                </li>
                <li className={currentPath === "ccbwcalc" ? 'active' : ''}>
                  <Link to="/calculator/ccbwcalc">Creatinine clearance calculator for adults</Link>
                </li>
                <li className={currentPath === "ibwcalc" ? 'active' : ''}>
                  <Link to="/calculator/ibwcalc">Ideal body weight calculator</Link>
                </li>
                <li className={currentPath === "lbwcalc" ? 'active' : ''}>
                  <Link to="/calculator/lbwcalc">Lean body weight calculator</Link>
                </li>
                <li className={currentPath === "gfrcalc" ? 'active' : ''}>
                  <Link to="/calculator/gfrcalc">Glomerular filtration rate (GFR) calculator for children</Link>
                </li>
                {/* <li className={currentPath === "Amikacin" ? 'active' : ''}> <Link to="/calculator/Amikacin">Amikacin Initial dose calculator for adults</Link> </li>
                <li className={currentPath === "Gentamicin" ? 'active' : ''}> <Link to="/calculator/Gentamicin">Gentamicin Initial dose calculator for adults</Link> </li>
                <li className={currentPath === "Tobramycin" ? 'active' : ''}> <Link to="/calculator/Tobramycin">Tobramycin Initial dose calculator for adults</Link> </li> */}
              </ul>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-9 col-lg-8 noLeftPadding tab-cal-container">
              <div className="tab-content tab-cal-content">
                <div className='tab-pane calc-content active'>
                  {currentPath === "bsacalc" && <BsaCalc />}
                  {currentPath === "ccbwcalc" && <CcbwCalc />}
                  {currentPath === "ibwcalc" && <IbwCalc />}
                  {currentPath === "lbwcalc" && <LbwCalc />}
                  {currentPath === "gfrcalc" && <GfrCalc />}
                  {/* {['Amikacin', 'Gentamicin', 'Tobramycin'].includes(currentPath) && <IdCalc currentPath={currentPath}/>} */}
                </div>
              </div>
            </div>
          </div>
        </div>
        { window.location.hostname !== "testtgl.powerappsportals.com" &&
        <>
          {!window.Microsoft && <Footer />}
        </> }
       
      </Suspense>
    </>
  );
}

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
});

export default connect(mapStateToProps, { push })(Calculators);