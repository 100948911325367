
import React, { useState, useRef } from 'react';
import bsacalc_formula from './images/body_surface_area_transparent.png'

const BsaCalc = (props) => {
  const inputHeightRef = useRef(null);
  const inputWeightRef = useRef(null);
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [bodySurfaceArea, setBodySurfaceArea] = useState('');
  const [errors, setErrors] = useState({});

  const calculate = () => {
    setBodySurfaceArea('');
    let errors = {};
    if (!height) {
      errors.height = 'Please enter a value for height.';
      inputHeightRef.current.focus();
    }

    if (!weight) {
      errors.weight = 'Please enter a value for weight.';
      inputWeightRef.current.focus();
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    const bsafCoef = 0.016667;
    const bsafHpow = 0.5;
    const bsafMpow = 0.5;

    let bsa = bsafCoef * Math.pow(height, bsafHpow) * Math.pow(weight, bsafMpow);
    bsa = Math.round(bsa * 100) / 100;
    setBodySurfaceArea(bsa);
  }

  const reset = () => {
    setHeight('');
    setWeight('');
    setBodySurfaceArea('');
  }

  return (
    <div>
      <h4  style={{fontWeight: 'bold'}} >Body surface area calculator</h4>
      <p>Enter the patient details into the calculator below. The body surface area is calculated using the following formula:</p>
      <p><img src={bsacalc_formula} id="bsacalc_formula" /></p>
      <div className="form-horizontal form-content-calc">
        <div className="form-group mTop12px">
          <label placeholder="Height" className="control-label col-lg-4 col-sm-6 col-xs-5">Height: </label>
          <div className="col-lg-3 col-sm-3 col-xs-6" style={{ display: 'flex', alignItems: 'center' }}>
            <div className="col-lg-9 noLeftPadding noRightPadding">
              <input
                name="height"
                type="number"
                className="form-control"
                id="heightCM"
                value={height}
                ref={inputHeightRef}
                min="0"
                onChange={(event) => {
                  setHeight(event.target.value);
                  setErrors((prev) => ({ ...prev, height: '' }));
                }}
              />
            </div>
            <div className="col-lg-3" style={{ paddingLeft: "20px" }}><div className="row">cm</div></div>
          </div>
        </div>
        
        <div className='row'>
          <div className="col-lg-4 col-sm-6 col-xs-6" />
          <div className="col-xs-8">
            {errors.height && <div className="invalid-feedback">{errors.height}</div>}
          </div>
        </div>

        <div className="form-group mTop12px">
          <label placeholder="Weight" className="control-label col-lg-4 col-sm-6 col-xs-5">Weight: </label>
          <div className="col-lg-3 col-sm-3 col-xs-6" style={{ display: 'flex', alignItems: 'center' }}>
            <div className="col-lg-9 noLeftPadding noRightPadding">
              <input
                name="mass"
                type="number"
                className="form-control"
                id="weightKG"
                value={weight}
                ref={inputWeightRef}
                min="0"
                onChange={(event) => {
                  setWeight(event.target.value);
                  setErrors((prev) => ({ ...prev, weight: '' }));
                }}
              />
            </div>
            <div className="col-lg-3" style={{ paddingLeft: "20px" }}><div className="row">kg</div></div>
          </div>
        </div>
        
        <div className='row'>
          <div className="col-lg-4 col-sm-6 col-xs-6" />
          <div className="col-xs-8">
            {errors.weight && <div className="invalid-feedback">{errors.weight}</div>}
          </div>
        </div>

        <div className="form-group mTop12px">
          <div className="col-sm-offset-4 col-sm-8 btncalc-container">
            <button className="btn btn-info btn-sm borderNone btn-calc btn-custom" id="calcBSA" onClick={() => calculate()}>Calculate</button>
            <button className="btn btn-default btn-sm borderNone btn-clr btn-custom" onClick={() => reset()}>Clear all</button>
          </div>
        </div>
        <div className="form-group mTop12px">
          <label placeholder="Body surface area" className="control-label col-lg-4 col-sm-6 col-xs-5">Body surface area: </label>
          <div className="col-lg-3 col-sm-3 col-xs-6" style={{ display: 'flex', alignItems: 'center' }}>
            <div className="col-lg-9 noLeftPadding noRightPadding">
              <input name="bsa_out" type="text" className="form-control" id="bsam2" value={bodySurfaceArea} readOnly />
            </div>
            <div className="col-lg-3" style={{ paddingLeft: "20px" }}><div className="row">m<sup>2</sup></div></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BsaCalc;
