import React, { useState, useRef } from 'react';
import { sanityCheck } from './utils';
import leanweight_m from './images/etg_abg15_appendix_figure3_male-lean-body-weight.png'
import leanweight_f from './images/etg_abg15_appendix_figure4_female-lean-body-weight.png'
import bmi from './images/etg_abg15_appendix_figure5_body-mass-index.png'

const LbwCalc = (props) => {
  const inputHeightRef = useRef(null);
  const inputActualBodyWeightRef = useRef(null);
  const [sex, setSex] = useState('Male');
  const [height, setHeight] = useState('');
  const [actualBodyWeight, setActualBodyWeight] = useState('');
  const [leanBodyWeight, setLeanBodyWeight] = useState('');
  const [errors, setErrors] = useState({});

  const calculate = () => {
    setLeanBodyWeight('');

    const calculatedHeight = height/100;
    
    let check = sanityCheck(
      height,
      actualBodyWeight,
      inputHeightRef,
      inputActualBodyWeightRef
    );
    if (check !== true) {
      setErrors(check);
      return;
    }

    const lbwBMI = actualBodyWeight / (calculatedHeight * calculatedHeight);
    const lbw = sex === "Male" ? (9270 * actualBodyWeight/(6680 + (216*lbwBMI))) : (9270 * actualBodyWeight/(8780 + (244*lbwBMI)));
    setLeanBodyWeight(Math.round(lbw));
  };

  const reset = () => {
    setSex('Male');
    setHeight('');
    setActualBodyWeight('');
    setLeanBodyWeight('');
  };

  return (
    <div>
      <h4  style={{fontWeight: 'bold'}} >Lean body weight calculator</h4>
      <div className="form-horizontal form-content-calc">
        <div className="form-group mTop12px item-center">
          <label className="control-label col-xs-4">Sex:</label>
          <div className="col-xs-6">
            <label className="radio-inline">
              <input className='big-checkbox' type="radio" checked={sex === 'Male'} value="Male" onChange={(event) => setSex(event.target.value)} />
              <div style={{padding: "0.5rem"}}> Male </div>
            </label>
          
            <label className="radio-inline">
              <input className='big-checkbox' type="radio" checked={sex === 'Female'} value="Female" onChange={(event) => setSex(event.target.value)} />
              <div style={{padding: "0.5rem"}}> Female </div>
            </label>
          </div>
        </div>
        <div className="form-group mTop12px"><label placeholder="Height" className="control-label col-lg-4 col-sm-6 col-xs-4">Height: </label>
          <div className="col-lg-3 col-sm-3 col-xs-6" style={{ display: 'flex', alignItems: 'center' }}>
            <div className="col-lg-9 noLeftPadding noRightPadding">
              <input type="number" min="0" className="form-control" id="lbwHeight" value={height} onChange={(event) => {
                setHeight(event.target.value);
                setErrors((prev) => ({ ...prev, height: '' }));
              }} ref={inputHeightRef}/>
            </div>
            <div name="gfrSeCr" className="col-lg-3" style={{ paddingLeft: "20px" }}><div className="row">cm</div></div>
          </div>
        </div>
        
        <div className='row'>
          <div className="col-lg-4 col-sm-6 col-xs-6" />
          <div className="col-xs-8">
            {errors.height && <div className="invalid-feedback">{errors.height}</div>}
          </div>
        </div>
        
        <div className="form-group mTop12px">
          <label placeholder="Actual body weight" className="control-label col-lg-4 col-sm-6 col-xs-4">Actual body weight: </label>
          <div className="col-lg-3 col-sm-3 col-xs-6" style={{ display: 'flex', alignItems: 'center' }}>
            <div className="col-lg-9 noLeftPadding noRightPadding">
              <input type="number" min="0" className="form-control" value={actualBodyWeight} id="lbwWeightActual" onChange={(event) => {
                setActualBodyWeight(event.target.value);
                setErrors((prev) => ({ ...prev, weight: '' }));
              }} ref={inputActualBodyWeightRef}/>
            </div>
            <div name="gfrSeCr" className="col-lg-3" style={{ paddingLeft: "20px" }}><div className="row">kg</div></div>
          </div>
        </div>
        
        <div className='row'>
          <div className="col-lg-4 col-sm-6 col-xs-6" />
          <div className="col-xs-8">
            {errors.weight && <div className="invalid-feedback">{errors.weight}</div>}
          </div>
        </div>

        <div className="form-group mTop12px">
          <div className="col-sm-offset-4 col-sm-8 btncalc-container d-flex justify-content-md-center">
            <button className="btn btn-info btn-sm borderNone btn-calc btn-custom" id="lbwcalc_compute" onClick={() => calculate()}>Calculate</button>
            <button className="btn btn-default btn-sm borderNone btn-clr btn-custom" onClick={() => reset()}>Clear all</button>
          </div>
        </div>
        <div className="form-group mTop12px"><label placeholder="Lean body weight" className="control-label col-lg-4 col-sm-6 col-xs-4">Lean body weight: </label>
          <div className="col-lg-3 col-sm-3 col-xs-6" style={{ display: 'flex', alignItems: 'center' }}>
            <div className="col-lg-9 noLeftPadding noRightPadding">
              <input type="text" value={leanBodyWeight} className="form-control" id="leanBodyWeight" readOnly />
            </div>
            <div name="gfrSeCr" className="col-lg-3" style={{ paddingLeft: "20px" }}><div className="row">kg</div></div>
          </div>
        </div>
      </div>
      <p>This calculator is based on the formula:</p>
      <table className="table table-striped table-bordered">
        <tbody>
          <tr>
            <td>
              <p>
                <img src={leanweight_m} id="lean_m" className="lbw_calcs" alt="" border="0"/>
                <br/>
                <img src={leanweight_f} id="lean_f" className="lbw_calcs" alt="" border="0"/>
                <br/>
                <img src={bmi} id="bmi" alt="" className="lbw_calcs" border="0"/>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default LbwCalc;
