import React, { useState, useRef } from 'react';
import { sanityCheckGfr } from './utils';
import gfr from './images/etg_abg15_appendix_figure1_children-renal-function.png'

const GfrCalc = () => {
  const inputHeightRef = useRef(null);
  const inputSerumCreatinineRef = useRef(null);
  const [height, setHeight] = useState('');
  const [creatinineValue, setCreatinineValue] = useState('');
  const [gfrEstimate, setGfrEstimate] = useState('');
  const [errors, setErrors] = useState({});

  const calculate = () => {
    setGfrEstimate('');
    setErrors({});

    let check = sanityCheckGfr(height, creatinineValue, inputHeightRef, inputSerumCreatinineRef);
    if (check !== true) {
      setErrors(check);
      return;
    }

    const gfrEstimate = 36.5 * height / creatinineValue;
    setGfrEstimate(Math.round(gfrEstimate));
  }

  const reset = () => {
    setHeight('');
    setCreatinineValue('');
    setGfrEstimate('');
  }

  return (
    <div>
      <h4  style={{fontWeight: 'bold'}} >Glomerular filtration rate (GFR) calculator for children</h4>
      <p>The calculator below provides an estimate of glomerular filtration rate. It should only be used for children older than 1 year.</p>
      <div className="form-horizontal form-content-calc">
        <div className="form-group mTop12px">
          <label placeholder="weight" className="control-label col-lg-4 col-xs-4">Height: </label>
          <div className="col-lg-3 col-sm-3 col-xs-8" style={{ display: 'flex', alignItems: 'center' }}>
            <div className="col-lg-9 col-xs-6 noLeftPadding noRightPadding">
              <input
                type="number"
                min="0"
                value={height}
                className="form-control"
                id="gfrHeight1"
                onChange={(event) => setHeight(event.target.value)}
                ref={inputHeightRef}
              />
            </div>
            <div className="col-lg-3 col-xs-6" style={{ paddingLeft: "20px" }}><div className="row">cm</div></div>
          </div>
        </div>
        
        <div className='row'>
          <div className="col-lg-4 col-sm-6 col-xs-6" />
          <div className="col-xs-8">
            {errors.height && <div className="invalid-feedback">{errors.height}</div>}
          </div>
        </div>

        <div className="form-group mTop12px">
          <label placeholder="age" className="control-label col-lg-4  col-xs-4">Serum creatinine: </label>
          <div className="col-lg-3 col-sm-3 col-xs-8" style={{ display: 'flex', alignItems: 'center' }}>
            <div className="col-lg-9 col-xs-6 noLeftPadding noRightPadding">
              <input
                type="number"
                min="0"
                value={creatinineValue}
                className="form-control"
                id="gfrSeCr1"
                onChange={(event) => setCreatinineValue(event.target.value)}
                ref={inputSerumCreatinineRef}
              />
            </div>
            <div className="col-lg-3 col-xs-6" style={{ paddingLeft: "20px" }}><div className="row">micromol/L</div></div>
          </div>
        </div>
        
        <div className='row'>
          <div className="col-lg-4 col-sm-6 col-xs-6" />
          <div className="col-xs-8">
            {errors.creatinine && <div className="invalid-feedback">{errors.creatinine}</div>}
          </div>
        </div>

        <div className="form-group mTop12px">
          <div className="col-sm-offset-4 col-sm-8 btncalc-container">
            <button className="btn btn-info btn-sm borderNone btn-calc btn-custom" id="gfrcalc_compute" onClick={() => calculate()}>Calculate</button>
            <button className="btn btn-default btn-sm borderNone btn-clr btn-custom" onClick={() => reset()}>Clear Form</button>
          </div>
        </div>
        <div className="form-group mTop12px">
          <label placeholder="GFR estimate" className="control-label col-lg-4 col-xs-4">GFR estimate: </label>
          <div className="col-lg-3 col-sm-3 col-xs-8" style={{ display: 'flex', alignItems: 'center' }}>
            <div className="col-lg-9 col-xs-6 noLeftPadding noRightPadding">
              <input type="text" className="form-control" id="gfrEstimate" value={gfrEstimate} readOnly />
            </div>
            <div name="gfrEstimate" className="col-lg-3 col-xs-6" style={{ paddingLeft: "20px" }}><div className="row">mL/min/1.73&nbsp;m<sup>2</sup></div></div>
          </div>
        </div>
      </div>
      <p>This calculator is based on the modified Schwartz formula:</p>
      <table className="table table-striped table-bordered">
        <tbody>
          <tr>
            <td>
              <p>
                <img src={gfr} id="gfr_formula" alt="" border="0" />
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default GfrCalc;
