import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { HashLink } from 'react-router-hash-link';
import { createPortal } from 'react-dom';
import { updateFavourites } from '../store/reducers/favourite';
import { updateState, emptyOpenSection } from '../features/counter/counterSlice';
import useTopicFunctions from '../hooks/useTopicFunctions'

const FavouriteModal = (props) => {
  const dispatch = useDispatch();
  const { openModalFlag, closeModal } = props;
  const [htmlContent, setHtmlContent] = useState([]);
  const { removeFav } = useTopicFunctions();
  
  useEffect(() => {
    const openLink = (href, section) => {
      const queryString = href.split('?')[1];
      const searchParams = new URLSearchParams(queryString);
      const guidelineName = searchParams.get('guidelinePage');

      const guidelinePage = guidelineName.replace(/ /g, '_');
      if (section) {
        const data = {
          ditaPath: `${guidelinePage}/`,
          queryString: {
            guidelinePage: encodeURIComponent(guidelineName),
            etgAccess: "true"
          },
          displayBanner: true,
          openSection: section,
        };

        dispatch(updateState(data));
      } else {
        const data = {
          ditaPath: `${guidelinePage}/`,
          queryString: {
            guidelinePage: encodeURIComponent(guidelineName),
            etgAccess: "true"
          },
          displayBanner: true,
          map: '',
          XRefPath: '',
        };

        dispatch(updateState(data));
        dispatch(emptyOpenSection());
      }
      
      closeModal();
    }

    const removeFavourite = (topic) => {
      removeFav(topic);
      dispatch(updateFavourites());
      loadContent();
    }

    const loadContent = () => {
      if (openModalFlag) {
        const content = [];
        let savedFavourites = localStorage.getItem('savedFavourites');
  
        if (savedFavourites && savedFavourites !== '{}') {
          savedFavourites = JSON.parse(savedFavourites);
          for (let index in savedFavourites) {
            let fav = '';
            if (savedFavourites[index].ditatitle == null || savedFavourites[index].ditatitle == "") {
              fav = savedFavourites[index].topic;
            } else {
              fav = `${savedFavourites[index].topic}:${savedFavourites[index].ditatitle}`;
            }
  
            content.push(
              <tr key={savedFavourites[index].topic}>
                <td className="mainText noborder">
                  <HashLink
                    className="favDisplay"
                    to={`${savedFavourites[index].href}`}
                    onClick={() => openLink(savedFavourites[index].href, savedFavourites[index].section)}
                  >
                    {fav}
                  </HashLink>
                  <span style={{ float: 'right' }} className="favDelete" onClick={() => removeFavourite(savedFavourites[index].topic)}>
                    <i className='fa fa-times'></i>
                  </span>
                </td>
              </tr>
            );
          };
  
          setHtmlContent(content);
        } else {
          setHtmlContent(
            <tr><td className="noborder text-center"><h3>No favourite added</h3></td></tr>
          );
        }
      }
    }

    loadContent();
  }, [openModalFlag]);

  return createPortal(
    <>
      <div className="modal-backdrop fade"></div>
      <div className="modal fade" id="myFavouritesModal" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" style={{ float: 'right' }} data-dismiss="modal" aria-label="Close" onClick={() => closeModal()}>
                <span aria-hidden="true">×</span>
              </button>
              <h4 className="modal-title" id="myFavourites">My favourites</h4>
            </div>
            <form id="favouritesList" name="favouritesList" action="favouritesList" method="post" autoComplete="off">
              <div className="modal-body">
                <table className="table" id="favListTable">
                  <tbody id="favContent">{htmlContent}</tbody>
                </table>
              </div>
              <div className="modal-footer">
              </div>
            </form>
          </div>
        </div>
      </div>
    </>,
    document.getElementById('portal')
  )
};

export default FavouriteModal;
