export const saneWeight = (weight) => {
  if (weight > 250) {
    return 'Weight more than 250kg entered; please check weight. For patients more than 250kg, seek expert advice.'
  }
  if (weight < 30) {
    return 'Weight less than 30kg entered; please check weight. For patients less than 30kg, seek expert advice.'
  }
  return true;
}

export const saneAge = (age) => {
  if (age > 100) {
    return 'age older than 100 years entered; please check age. For patients older than 100 years, seek expert advice.'
  } else if (age < 18) {
    return 'Age entered is less than 18 years.\nThis calculator should only be used for patients older than 18 years.'
  }
  return true;
}

export const saneHeight = (height) => {
  if (height > 220) {
    return 'Height more than 220cm entered; please check height. For patients taller than 220cm, seek expert advice.'
  } else if (height < 90) {
    return 'Height less than 90cm entered; please check height. For patients shorter than 90cm, seek expert advice.'
  }
  return true;
}


export const checkNum = (val, text) => {
  if ((val === null) || (val === "")) {
    return "Please enter a value for " + text + "."
  } else if (isNaN(val)) {
    return "Please enter only numeric characters in the " + text + " field."
  } else if (val < 0) {
    return "Negative " + text + " entered.\nPlease enter valid " + text + "."
  }
  return true;
}

export const saneSerum = (serum) => {
  //alert(serum + ' millimoles/l or ' + serum*1000 + ' micromoles/l');
  //Limits are therefore 5 to 10000 micromoles/l
  if (serum > 10000 || serum < 5) {
    return 'The serum creatinine value entered is unusual. Please check it.'
  }
  return true;
}

export const rounding = (number, decimal) => {
  const multiplier = Math.pow(10, decimal);
  number = Math.round(number * multiplier) / multiplier;
  return number;
}

export const roundOne = (number) => {
  number = number * 10;
  number = Math.round(number);
  number = number / 10;

  return number;
}

export const sanityCheck = (height, weight, inputHeightRef, inputActualBodyWeightRef) => {
  let errors = { height: "", weight: "" };

  if (height === "") {
    errors.height = "Please enter a value for height.";
  } else if(isNaN(height)){
    errors.height = "Please enter only numeric characters in the height field.";
  } else if (height < 100) {
    errors.height = "Very small height entered.";
  } else if (height > 220) {
    errors.height = "Very large height entered.";
  }

  if (weight === "") {
    errors.weight = "Please enter a value for actual body weight.";
  } else if(isNaN(weight)){
    errors.weight = "Please enter only numeric characters in the actual body weight field.";
  } else if (weight < 40) {
    errors.weight = "Very small actual weight entered.";
  } else if (weight > 200) {
    errors.weight = "Very large actual weight entered.";
  }

  return errors.height === "" && errors.weight === "" ? true : errors;
}

export const sanityCheckGfr = (height, creatinineValue, inputHeightRef, inputSerumCreatinineRef) => {
  let errors = { height: "", creatinine: "" };

  if (!height) {
    errors.height = "Please enter a value for height.";
  } else if (isNaN(height)) {
    errors.height = "Please enter only numeric characters into the height field.";
  } else if (height < 50) {
    errors.height = "Very small height entered.";
  } else if (height > 200) {
    errors.height = "Very large height entered.";
  }

  if (!creatinineValue) {
    errors.creatinine = "Please enter a value for serum creatinine.";
  } else if (isNaN(creatinineValue)) {
    errors.creatinine = "Please enter only numeric characters into the serum creatinine field.";
  } else if (creatinineValue < 20) {
    errors.creatinine = "Very small value entered for serum creatinine.";
  } else if (creatinineValue > 1000) {
    errors.creatinine = "Very large value entered for serum creatinine.";
  }

  if (errors.height || errors.creatinine) {
    return errors;
  }
  return true;
}


export const initialise = (passed) => {
  const str=passed;
	const str2 = str.charAt(0).toUpperCase() + str.slice(1);
	return str2;
}
