import React, { useState, useRef } from 'react';
import { roundOne } from './utils';
import idealweight_m from './images/etg_abg15_appendix_figure6_male-ideal-body-weight.png'
import idealweight_f from './images/etg_abg15_appendix_figure7_female-ideal-body-weight.png'

const IbwCalc = (props) => {
  const inputHeightRef = useRef(null);
  const inputHeightUnitRef = useRef(null);
  const [height, setHeight] = useState('');
  const [heightUnit, setHeightUnit] = useState('cm');
  const [sex, setSex] = useState('Male');
  const [idealBodyWeight, setIdealBodyWeight] = useState('');
  const [errors, setErrors] = useState({});

  const calculate = () => {
    const doCalc = true;
    if (!height) {
      setErrors((prev) => ({ ...prev, height: 'Please enter a value for height.' }));
      inputHeightRef.current.focus();
      return;
    }

    const paramValue = parseFloat(height);
    let idealWeight = '';
    let sexValue = '';
    let mOver = "";
    let heightValue = paramValue;

    if (sex.includes('Male')) {
      sexValue = 50;
    } else if (sex.includes('Female')) {
      sexValue = 45.5;
    }

    if (heightUnit === 'cm') {
      if (heightValue <= 152) {
        idealWeight = sexValue;
      } else {
        mOver = heightValue - 152;
        idealWeight = sexValue + (.90 * mOver);
      }

      if (heightValue >= 300) {
        setErrors((prev) => ({ ...prev, height: 'Very large height entered.' }));
        inputHeightRef.current.focus();
        return;
      }

      if (heightValue <= 100) {
        setErrors((prev) => ({ ...prev, height: 'Very small height entered.' }));
        inputHeightRef.current.focus();
        return false;
      }
    } else if (heightUnit === 'inches') {
      if (heightValue <= 60) {
        idealWeight = sexValue;
      } else {
        mOver = heightValue - 60;
        idealWeight = sexValue + (2.3 * mOver);
      }

      if (heightValue >= 120) {
        setErrors((prev) => ({ ...prev, height: 'Very large height entered.' }));
        inputHeightRef.current.focus();
        return false;
      }

      if (heightValue <= 40) {
        setErrors((prev) => ({ ...prev, height: 'Very small height entered.' }));
        inputHeightRef.current.focus();
        return false;
      }
    }

    const ibw = roundOne(idealWeight);
    if (doCalc) {
      setIdealBodyWeight(ibw);
    }
  };

  const reset = () => {
    setHeight('');
    setHeightUnit('');
    setSex('Male');
  };

  return (
    <div>
      <h4  style={{fontWeight: 'bold'}} >Ideal body weight calculator</h4>
        <div className="form-horizontal form-content-calc">
          <div className="form-group mTop12px">
            <label placeholder="height" className="control-label col-lg-4 col-sm-6 col-xs-4">Height :</label>
            <div className="col-lg-6 col-sm-3 col-xs-8">
              <div className='row'>
                <div className="col-lg-4 col-xs-7">
                  <input
                    type="number"
                    size="5"
                    className="form-control"
                    value={height}
                    onChange={(event) => {
                      setHeight(event.target.value);
                      setErrors((prev) => ({ ...prev, height: '' }));
                    }}
                    id="c3height"
                    min="0"
                    ref={inputHeightRef}
                    required
                  />
                </div>
                <div className="col-lg-4 col-xs-4" style={{ paddingLeft: "20px" }}>
                  <div className="row">
                    <select
                      className="form-control"
                      value={heightUnit}
                      onChange={(event) => setHeightUnit(event.target.value)}
                      ref={inputHeightUnitRef}
                    >
                      <option value="cm">cm</option>
                      <option value="inches">inches</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        <div className='row'>
          <div className="col-lg-4 col-sm-6 col-xs-6" />
          <div className="col-xs-8">
            {errors.height && <div className="invalid-feedback">{errors.height}</div>}
          </div>
        </div>


        <div className="form-group mTop12px item-center">
          <label className="control-label col-xs-4">Sex:</label>
          <div className="col-xs-7">
            <label className="radio-inline">
              <input className='big-checkbox' type="radio" checked={sex === 'Male'} value="Male" onChange={(event) => setSex(event.target.value)} />
              <div style={{padding: "0.5rem"}}> Male </div>
            </label>
          
            <label className="radio-inline">
              <input className='big-checkbox' type="radio" checked={sex === 'Female'} value="Female" onChange={(event) => setSex(event.target.value)} />
              <div style={{padding: "0.5rem"}}> Female </div>
            </label>
          </div>
        </div>

          <div className="form-group mTop12px">
            <div className="col-sm-offset-4 col-sm-8 btncalc-container">
              <button id="ibwcalc_compute" className="btn btn-info btn-sm borderNone btn-calc btn-custom" onClick={() => calculate()}>Calculate</button>
              <button className="btn btn-default btn-sm borderNone btn-clr btn-custom" onClick={() => reset()}>Clear Form</button>
            </div>
          </div>
          <div className="form-group mTop12px">
            <label placeholder="Ideal body weight" className="control-label col-lg-4 col-sm-6 col-xs-4">Ideal body weight :</label>
            <div className="col-lg-3 col-sm-3 col-xs-8 align-center">
              <div className="col-lg-9 col-xs-7 noLeftPadding noRightPadding">
                <input type="text" className="form-control" id="c3kg" value={idealBodyWeight} readOnly />
              </div>
              <div className="col-lg-3" style={{ paddingLeft: "20px" }}><div className="row">kg</div></div>
            </div>
          </div>
      </div>
      <p>This calculator is based on the formula:</p>
      <table className="table table-striped table-bordered">
        <tbody>
          <tr>
            <td>
              <p className="eptabletext">
                <img src={idealweight_m} class="ibwcalc_formula" alt="" width="465" height="40" border="0" />
              </p>
              <p className="eptabletext">
                <img src={idealweight_f} class="ibwcalc_formula" alt="" width="465" height="40" border="0" />
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default IbwCalc;
