import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSortResults, selectSortResults } from '../../store/reducers/search';



const SortResults = (props)=>{
  const dispatch = useDispatch();
  const selectedSort = useSelector(selectSortResults);
  const handleChange = (event) => {
    // Dispatch the new sort option selected by the user
    
    dispatch(setSortResults(event.target.value));
  };
  
  return(
    <div id="sortResults" className="input-group sortResults">
    <div className="form-group">
      <select 
        id="sortBy" 
        className="sortOptions form-control"
        value={selectedSort}
        onChange={handleChange}
      >
        <option value="0">Relevance</option>
        <option value="1">Title Ascending (A-Z)</option>
        <option value="2">Title Decending (Z-A)</option>
      </select>
    </div>
  </div>
  )
}
export default React.memo(SortResults);